import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Friday's AMA + 25 Day Recap On The Way`}</h1>
    <p><strong parentName="p">{`Yo`}</strong>{`,`}</p>
    <p>{`The Lakers won it all yesterday, so this week is all about having that Alex
Caruso energy and hustle. If you don't know who he is,
`}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/o8umz8d5dgsqhxq9kqiv/kkhmh6h97pd08qbl/aHR0cHM6Ly93d3cuc2lsdmVyc2NyZWVuYW5kcm9sbC5jb20vMjAyMC81LzIwLzIxMjYzODI4L2xha2Vycy1uZXdzLWFsZXgtY2FydXNvLXByb2ZpbGUtdW5kZXJkb2ctc291dGgtYmF5LWctbGVhZ3VlLW5iYS10d28td2F5LWNvbnRyYWN0LXN0b3J5LXVuZHJhZnRlZA=="
      }}>{`read this`}</a>{`!`}</p>
    <p>{`Basically, he scrapped his way from being an undrafted free agent --> G League
--> 10-day contract --> starting the NBA finals and winning a 💍 ...gotta love
that`}</p>
    <p><strong parentName="p">{`Miss Friday's AMA? 💬`}</strong></p>
    <p><strong parentName="p">{`Click`}</strong>{` `}<a parentName="p" {...{
        "href": "https://vimeo.com/466730473/ed2ede32d4"
      }}>{`here`}</a>{` `}<strong parentName="p">{`and you can watch all
75 mins - lots of good questions in this one!`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1524px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "55.99999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQDBQb/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAABYgVrwtAKBv8A/8QAGxAAAQQDAAAAAAAAAAAAAAAAAgABAxIhMTL/2gAIAQEAAQUCsIqOV2JzzNwW6Cv/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGn/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8BCf/EABkQAQACAwAAAAAAAAAAAAAAAAEAEQIgMf/aAAgBAQAGPwK1gOVjpyf/xAAbEAACAwADAAAAAAAAAAAAAAAAAREhMVFhkf/aAAgBAQABPyFzYSgTdXqdOcW8mvTontq2f//aAAwDAQACAAMAAAAQa+//xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8QcM//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAIAQIBAT8QpiP/xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhMUFhgZH/2gAIAQEAAT8QAqtWm/O46aK6UdmecTIofZzM5HmYkrXV0hZXNqT/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Friday AMA",
          "title": "Friday AMA",
          "src": "/static/cfa89f0da5c2055c8e0b22e10052b7e4/045dc/ama-screenshot.jpg",
          "srcSet": ["/static/cfa89f0da5c2055c8e0b22e10052b7e4/a6407/ama-screenshot.jpg 400w", "/static/cfa89f0da5c2055c8e0b22e10052b7e4/a24e6/ama-screenshot.jpg 800w", "/static/cfa89f0da5c2055c8e0b22e10052b7e4/045dc/ama-screenshot.jpg 1524w"],
          "sizes": "(max-width: 1524px) 100vw, 1524px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`25-Day Key Takeaways 🔑`}</strong></p>
    <p>{`We've spent the past 5-6 weeks putting out a ton of content and I know that not
everybody can read (even though open rates are at like 84%!) or retain
everything that i put out into the AAP world`}</p>
    <p>{`So, we spent today prototyping a 25-day recap for you with all the best
stuff...think of it like your guide to the most important things from the AAP
that you should use in your life and business.`}</p>
    <p>{`Here's a sneak peek where Ben reviewed all of the AAP posts and pulled out the
best templates, tactics, resources, frameworks, etc.`}</p>
    <p><strong parentName="p">{`I know these are impossible to read, so click the image to load the full
image, if you wanna be able to read the screenshots!`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://www.dropbox.com/s/qcylxv1je10wlkq/List%20of%20Posts.png?dl=0"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAQAF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdokYpP/xAAXEAEBAQEAAAAAAAAAAAAAAAABAAIR/9oACAEBAAEFAjsRBGbJw//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABkQAQEBAQEBAAAAAAAAAAAAAAEAESFRQf/aAAgBAQABPyH2t/Ubs3JxeyHk+N//2gAMAwEAAgADAAAAEPD/AP/EABYRAQEBAAAAAAAAAAAAAAAAAAABMf/aAAgBAwEBPxBMf//EABYRAQEBAAAAAAAAAAAAAAAAAAABMf/aAAgBAgEBPxBMf//EABsQAQACAwEBAAAAAAAAAAAAAAEAESFRYTFB/9oACAEBAAE/EFRKOuTClpqNVAt8sZdAYXl4yyyEUfk//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Best of sheet 1",
            "title": "Best of sheet 1",
            "src": "/static/f0bef4326090dbbbdfc3d96bc1acc520/04bec/aap-best-of-sheet-1.jpg",
            "srcSet": ["/static/f0bef4326090dbbbdfc3d96bc1acc520/a6407/aap-best-of-sheet-1.jpg 400w", "/static/f0bef4326090dbbbdfc3d96bc1acc520/a24e6/aap-best-of-sheet-1.jpg 800w", "/static/f0bef4326090dbbbdfc3d96bc1acc520/04bec/aap-best-of-sheet-1.jpg 1600w", "/static/f0bef4326090dbbbdfc3d96bc1acc520/fdb5a/aap-best-of-sheet-1.jpg 2400w", "/static/f0bef4326090dbbbdfc3d96bc1acc520/35d19/aap-best-of-sheet-1.jpg 2636w"],
            "sizes": "(max-width: 1600px) 100vw, 1600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></a></p>
    <p><a parentName="p" {...{
        "href": "https://www.dropbox.com/s/rs7ck1e7wfyr1mb/Frameworks%20and%20Resources.png?dl=0"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.75000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHeSxCRg//EABYQAAMAAAAAAAAAAAAAAAAAAAEQIP/aAAgBAQABBQKAv//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABsQAAICAwEAAAAAAAAAAAAAAAABIUEQEVHB/9oACAEBAAE/IeQU4FK34Ivj/9oADAMBAAIAAwAAABDDz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABsQAQACAgMAAAAAAAAAAAAAAAEAETFBIXGh/9oACAEBAAE/EEvZXkQERvlLgAoNm4wmPZhif//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Best of sheet 2",
            "title": "Best of sheet 2",
            "src": "/static/ab971fa2f2d2f372bff88d69bc7482ae/04bec/aap-best-of-sheet-2.jpg",
            "srcSet": ["/static/ab971fa2f2d2f372bff88d69bc7482ae/a6407/aap-best-of-sheet-2.jpg 400w", "/static/ab971fa2f2d2f372bff88d69bc7482ae/a24e6/aap-best-of-sheet-2.jpg 800w", "/static/ab971fa2f2d2f372bff88d69bc7482ae/04bec/aap-best-of-sheet-2.jpg 1600w", "/static/ab971fa2f2d2f372bff88d69bc7482ae/fdb5a/aap-best-of-sheet-2.jpg 2400w", "/static/ab971fa2f2d2f372bff88d69bc7482ae/14c8c/aap-best-of-sheet-2.jpg 3200w", "/static/ab971fa2f2d2f372bff88d69bc7482ae/0c7b9/aap-best-of-sheet-2.jpg 3668w"],
            "sizes": "(max-width: 1600px) 100vw, 1600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></a></p>
    <p><strong parentName="p">{`Now to spend the rest of the night distilling all of this down into something
that you can digest!`}</strong></p>
    <p>{`until tomorrow,`}</p>
    <p>{`shaan`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      